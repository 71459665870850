import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  Duration,
  ViewMessage,
  ViewLoading,
  AppBody
} from "@soniox/common-new";

import { getDashboardData } from "../../api";
import { IDashboard } from "../../app/models";

import "./style.scss";

export default function DashboardView() {
  const [status, setStatus] = useState("idle");
  const [dashboardData, setDashboardData] = useState<IDashboard | null>(null);

  const getInitialData = async () => {
    const response = await getDashboardData();

    if (response.ok()) {
      setDashboardData(response.data());

      setStatus("succeeded");
    } else {
      setStatus("failed");
    }
  };

  // On mount.
  useEffect(() => {
    setStatus("loading");
    getInitialData();
  }, []);

  const displayLoading = () => {
    return <ViewLoading position="" msg={"Loading dashboard data ..."} />;
  };

  const displayFailed = () => {
    return (
      <ViewMessage position="" variant="failed">
        <p>Failed to load user data. Please try again.</p>
      </ViewMessage>
    );
  };

  const displaySucceeded = () => {
    if (!dashboardData) {
      return displayFailed();
    }

    const { name, current_month_usage_ms } = dashboardData;

    return (
      <div className="component-dashboard-view">
        <div className="component-title">
          <h3>{`Welcome, ${name}!`}</h3>
        </div>
        <Container>
          <Row className="align-items-stretch">
            <Col lg={8}>
              <div className="s-section">
                <div className="s-section-border">
                  <div className="s-section-title">
                    <h4 className="h5">Current Month Summary</h4>
                  </div>
                  <div className="s-section-body">
                    <p>
                      Total Time Transcribed:{" "}
                      <strong>
                        <Duration miliseconds={current_month_usage_ms} />
                      </strong>
                    </p>
                    <p>
                      {current_month_usage_ms > 0 && (
                        <Link to="/billing-activity/current-month/">
                          See usage details
                        </Link>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const content = () => {
    if (status === "idle" || status === "loading") {
      return displayLoading();
    } else if (status === "failed") {
      return displayFailed();
    } else if (status === "succeeded") {
      return displaySucceeded();
    }
  };

  return (
    <AppBody className="component-dashboard-view">
      <div className="component-view container container-xxl">{content()}</div>
    </AppBody>
  );
}
