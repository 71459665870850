export interface PriceProps {
  value: number;
  currency?: string;
}

export function Price({ value, currency = "USD" }: PriceProps) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  });

  return <>{formatter.format(value)}</>;
}
