import { useContext, createContext } from "react";
import { API_PREFIX } from "../app/config";
import { Auth } from "@soniox/common-new";

const auth: Auth = new Auth(`${API_PREFIX}token/`);
const AuthContext = createContext<Auth | null>(null);

interface AuthProviderProps {
  children?: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext<Auth | null>(AuthContext);
}
