// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { getJwt, unsetJwt } from "../auth/jwt";
import { getCookie } from "../utils/cookie";

export const DOMAIN = process.env.REACT_APP_API_URL || "";

// X-CSRFToken.
const csrftoken = getCookie("csrftoken");

// Response.

export class Response {
  private _response;
  private _data;

  constructor(response: Response, data: any) {
    this._response = response;
    this._data = data;
  }

  ok() {
    return this._response.ok;
  }

  status() {
    return this._response.status;
  }

  data() {
    return this._data;
  }

  keys() {
    return Object.keys(this._data);
  }

  getValue(key: any) {
    if (key in this._data) {
      return this._data[key];
    }
    return null;
  }

  getValueStr(key: any, separator = " ") {
    if (key in this._data) {
      return this._data[key].join(separator);
    }
    return null;
  }
}

export const unauthResponse = new Response({ ok: false, status: 401 }, {});

// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint: string, { method, body, ...customConfig } = {}, jwt = null) {
  const path = DOMAIN + endpoint;

  let headers = { "Content-Type": "application/json" };

  if (jwt == null) {
    headers["X-CSRFToken"] = csrftoken;
  } else {
    headers["X-Access-Token"] = "Bearer " + jwt.access;
  }

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await window.fetch(path, config);
    const data_str = await response.text();

    // If body is not empty, it should be a json string.
    let data = {};
    if (data_str.length > 0) {
      data = JSON.parse(data_str);
    }

    return new Response(response, data);
  } catch (err) {
    return new Response(
      { ok: false, status: 500 },
      { error: "Unexpected error. Please try again later." }
    );
  }
}

client.get = function (endpoint: string, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint: string, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "POST", body });
};

client.auth = async function (endpoint: string, { method, body, ...customConfig } = {}) {
  // Authenticated version of the client() method. It will redirect to signin if:
  // (1) JWT token is not set.
  // (2) Request failed with the 401 code ("Authentication failed" code).
  //
  // Note: we can not use history object inside a non-react component (use
  // window.location.href instead).

  const jwt = getJwt();
  if (jwt == null) {
    // JWT token is not set. Redirect to signin.
    window.location.href = "/signin";

    // We shoud / have to return some value.
    return unauthResponse;
  }

  const response = await client(endpoint, { method, body, ...customConfig }, jwt);

  if (response.status() == 401) {
    // Authentication failed. Unset jwt and redirect to signin.
    unsetJwt();
    window.location.href = "/signin";

    // We shoud / have to return some value.
    return unauthResponse;
  }

  return response;
};

client.authGet = function (endpoint: string, customConfig = {}) {
  return client.auth(endpoint, { method: "GET", ...customConfig });
};

client.authPost = function (endpoint: string, body: any, customConfig = {}) {
  return client.auth(endpoint, { method: "POST", body, ...customConfig });
};

client.authPut = function (endpoint: string, body: any, customConfig = {}) {
  return client.auth(endpoint, { method: "PUT", body, ...customConfig });
};

client.authDelete = function (endpoint: string, body: any, customConfig = {}) {
  return client.auth(endpoint, { method: "DELETE", body, ...customConfig });
};
