import { Spinner } from "react-bootstrap";

import "../BoxLoading/style.scss";

interface ViewLoadingProps {
  position: string;
  msg: string;
}

export function ViewLoading({ position, msg }: ViewLoadingProps) {
  const getPositionClass = () => {
    switch (position) {
      case "static":
        return "s-show-as-static";
      default:
        return "s-show-as-overlay";
    }
  };

  return (
    <div className={`s-box-loading s-view-loading ${getPositionClass()}`}>
      <Spinner animation="border" variant="primary" />
      {msg && <span className={"s-box-loading-message"}>{msg}</span>}
    </div>
  );
}
