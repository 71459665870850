export interface DurationProps {
  className?: string;
  seconds?: number;
  miliseconds?: number;
}

function humanReadableDuration(durationMs: number) {
  const seconds = Math.floor((durationMs / 1000) % 60);
  const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
  const hours = Math.floor(durationMs / (1000 * 60 * 60));

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }

  return `${seconds}s`;
}

export function Duration({ className, miliseconds = 0 }: DurationProps) {
  return <time className={className}>{humanReadableDuration(miliseconds)}</time>;
}
