import { NavLink } from "react-router-dom";

const ACTIVE_CLASS_NAME = "is-active";

export default function SideNavigationItems() {
  return (
    <ul className="app-side-menu">
      <li>
        <NavLink
          activeClassName={ACTIVE_CLASS_NAME}
          to="/dashboard"
          title="Dashboard">
          <i className="material-icons s-icon">dashboard</i>
          <span>Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName={ACTIVE_CLASS_NAME}
          to="/billing-activity/"
          title="Billing activity">
          <i className="material-icons s-icon">receipt</i>
          <span>Billing Activity</span>
        </NavLink>
      </li>
    </ul>
  );
}
