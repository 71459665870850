import { ReactNode } from "react";

import "../BoxMessage/style.scss";

export interface ViewMessageProps {
  variant: string;
  position: string;
  children?: ReactNode;
}

export function ViewMessage({ variant, position, children }: ViewMessageProps) {
  const getPositionClass = () => {
    switch (position) {
      case "static":
        return "s-show-as-static";
      default:
        return "s-show-as-overlay";
    }
  };

  const displayIcon = () => {
    switch (variant) {
      case "failed":
      case "error":
        return <i className={"material-icons"}>error</i>;
      case "warning":
        return <i className={"material-icons"}>warning</i>;
      case "completed":
        return <i className={"material-icons"}>done_outline</i>;
      case "mail-sent":
        return <i className={"material-icons"}>mail_outline</i>;
      case "info":
        return <i className={"material-icons"}>error</i>;
      case "waiting":
        return <i className={"material-icons"}>hourglass_top</i>;
      default:
        return;
    }
  };

  return (
    <div className={`s-box-message s-view-message ${getPositionClass()}`}>
      {variant && <div className={"s-box-message-icon"}>{displayIcon()}</div>}
      <div className={"s-box-message-body"}>{children}</div>
    </div>
  );
}
