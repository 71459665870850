import { AppBody } from "@soniox/common-new";
import { Route, Switch } from "react-router-dom";

import BillingActivity from "./BillingActivity";
import BillingActivityDetails from "./BillingActivityDetails";

import "./style.scss";

export default function BillingActivityView() {
  return (
    <AppBody className="component-billingActivity-view">
      <div className="component-view container container-xxl">
        <Switch>
          <Route
            path={[
              "/billing-activity/current-month/",
              "/billing-activity/:reportId/"
            ]}>
            <BillingActivityDetails />
          </Route>
          <Route path="/billing-activity/">
            <BillingActivity />
          </Route>
        </Switch>
      </div>
    </AppBody>
  );
}
