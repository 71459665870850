import { createContext, useContext, useState, useEffect, ReactNode } from "react";

export interface WindowDimensions {
  height: number;
  width: number;
  breakpoint: string;
}

export interface ResponsiveDimensionsProps {
  children: ReactNode | Array<ReactNode>;
}

export interface ResponsiveDimensionsInterface {
  dimensions: WindowDimensions;
  setDimensions: Function;
}

const defaultResponsiveDimensionsValues = {
  dimensions: {
    height: 0,
    width: 0,
    breakpoint: "md"
  },
  setDimensions: () => {}
};

export const ResponsiveDimensionsContext = createContext<ResponsiveDimensionsInterface>(
  defaultResponsiveDimensionsValues
);

export const BREAKPOINT_XS_NAME = "xs";
const BREAKPOINT_SM = 576;
export const BREAKPOINT_SM_NAME = "sm";
const BREAKPOINT_MD = 768;
export const BREAKPOINT_MD_NAME = "md";
const BREAKPOINT_LG = 992;
export const BREAKPOINT_LG_NAME = "lg";
const BREAKPOINT_XL = 1200;
export const BREAKPOINT_XL_NAME = "xl";
const BREAKPOINT_XXL = 1400;
export const BREAKPOINT_XXL_NAME = "xxl";

const windowDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
  breakpoint: getBreakpoint(window.innerWidth)
});

const getBreakpoint = (width: number) => {
  let breakpoint = "sm";
  // X-Small	          None	  <576px
  // Small	            sm	    ≥576px
  // Medium	            md	    ≥768px
  // Large	            lg	    ≥992px
  // Extra large	      xl	    ≥1200px
  // Extra extra large	xxl	    ≥1400px
  if (width < BREAKPOINT_SM) {
    breakpoint = BREAKPOINT_XS_NAME;
  } else if (width >= BREAKPOINT_SM && width < BREAKPOINT_MD) {
    breakpoint = BREAKPOINT_SM_NAME;
  } else if (width >= BREAKPOINT_MD && width < BREAKPOINT_LG) {
    breakpoint = BREAKPOINT_MD_NAME;
  } else if (width >= BREAKPOINT_LG && width < BREAKPOINT_XL) {
    breakpoint = BREAKPOINT_LG_NAME;
  } else if (width >= BREAKPOINT_XL && width < BREAKPOINT_XXL) {
    breakpoint = BREAKPOINT_XL_NAME;
  } else if (width >= BREAKPOINT_XXL) {
    breakpoint = BREAKPOINT_XXL_NAME;
  }
  return breakpoint;
};

export const ResponsiveDimensionsProvider = (props: ResponsiveDimensionsProps) => {
  const [dimensions, setDimensions] = useState(windowDimensions());
  useEffect(() => {
    const handleResize = () => {
      setDimensions(windowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ResponsiveDimensionsContext.Provider value={{ dimensions, setDimensions }}>
      {props.children}
    </ResponsiveDimensionsContext.Provider>
  );
};

export const useWindowDimensions = () => {
  return useContext(ResponsiveDimensionsContext);
};
