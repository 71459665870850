import { ReactNode } from "react";

import "./style.scss";

export interface FieldErrorMessageProps {
  children?: ReactNode;
}

export function FieldErrorMessage({ children }: FieldErrorMessageProps) {
  return <div className="field-error-msg">{children}</div>;
}
