import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import sonioxLogo from "../../assets/sonioxLogoWhite.svg";
import { AppHeaderDropdown } from "../AppHeaderDropdown";

import "./style.scss";

export interface AppHeaderProps {
  logoTitle: string;
  children?: React.ReactNode;
  isAuthenticated: boolean;
}

export function AppHeader({ logoTitle, isAuthenticated, children }: AppHeaderProps) {
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);

  const handleOnClickToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOnClickCloseDropdown = () => {
    setShowDropdown(false);
  };

  // On location (url) change
  useEffect(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  }, [location]);

  const displayRestrictedHeader = () => {
    return (
      <div className={"main-navigation"}>
        <ul>
          <li className={"s-dropdown-wrapper"}>
            <button className={"btn s-header-btn"} onClick={() => handleOnClickToggleDropdown()}>
              <i className={"material-icons"}>face</i>
            </button>
            <AppHeaderDropdown
              status={showDropdown}
              title={"Hello"}
              onClose={handleOnClickCloseDropdown}>
              {children}
            </AppHeaderDropdown>
          </li>
        </ul>
      </div>
    );
  };

  const displayPublicHeader = () => {
    return (
      <div className={"main-navigation"}>
        <ul>
          <li>
            <Link to="/signin">Sign In</Link>
          </li>
        </ul>
      </div>
    );
  };

  const displayHeaderLogo = () => {
    return (
      <Link to="/" className={"app-logo-box"}>
        <img src={sonioxLogo} className={"app-logo"} alt={"Soniox Cloud"} />
        {logoTitle && <span className={"app-logo-title"}>{logoTitle}</span>}
      </Link>
    );
  };

  return (
    <header className={"app-header"}>
      {displayHeaderLogo()}
      <div className={"spacer"}></div>
      {isAuthenticated ? displayRestrictedHeader() : displayPublicHeader()}
    </header>
  );
}
