import { client } from "../api/client";
import { setJwt, unsetJwt, hasJwt, getJwt } from "./jwt";

export class Auth {
  private _authEdnpoint: string;

  constructor(authEdnpoint: string) {
    this._authEdnpoint = authEdnpoint;
  }

  async signin(email: string, password: string, callback: (response: any) => void): Promise<void> {
    if (hasJwt()) {
      throw new Error("jwt already set.");
    }

    const response = await client.post(this._authEdnpoint, {
      username: email,
      password: password
    });

    if (response.ok()) {
      // Succeeded.
      setJwt(response.data());
    }

    callback(response);
  }

  async signout(callback: () => void) {
    if (!hasJwt()) {
      throw new Error("jwt already unset.");
    }

    unsetJwt();
    callback();
  }

  ok() {
    // If jwt exists in storage, we assume the authentication is OK.
    // The validity of jwt is handled in client.auth() function.
    return hasJwt();
  }

  jwt() {
    return getJwt();
  }

  unsetJwt() {
    if (!hasJwt()) {
      throw new Error("jwt already unset.");
    }

    unsetJwt();
  }
}
