import { ReactNode } from "react";

import { SideNavigation, useResponsiveLayout } from "@soniox/common-new";

export interface PrivateRouteLayoutProps {
  className?: string | null;
  children: ReactNode | Array<ReactNode>;
  sideNavigationItems: ReactNode | Array<ReactNode> | null;
}

export default function PrivateRouteLayout(props: PrivateRouteLayoutProps) {
  const sideNavigationExpanded = useResponsiveLayout()?.sideNavigationExpanded;
  const { className, children, sideNavigationItems } = props;

  const setLeftPanelClassName = () => {
    const className = "left";
    return sideNavigationExpanded
      ? `${className} s-expanded`
      : `${className} s-collapsed`;
  };

  const shouldShowSideNavigation = () => {
    if (sideNavigationItems) {
      return true;
    }

    return false;
  };

  return (
    <div className={`app-main component-private-route-layout ${className}`}>
      {shouldShowSideNavigation() && (
        <div className={setLeftPanelClassName()}>
          <SideNavigation>{sideNavigationItems}</SideNavigation>
        </div>
      )}
      <div className="middle">{children}</div>
    </div>
  );
}
