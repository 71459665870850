import { hasJwt } from "@soniox/common-new";
import create from "zustand";

// Auth store
interface AuthStore {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  userId: number | null;
  setUserId: (value: number) => void;
}

export const authStore = create<AuthStore>((set) => ({
  isAuthenticated: hasJwt(),
  setIsAuthenticated: (value: boolean) =>
    set(() => ({ isAuthenticated: value })),

  userId: null,
  setUserId: (value: number) => set(() => ({ userId: value }))
}));
