import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { Duration, Price, ViewLoading, ViewMessage } from "@soniox/common-new";

import { getBillingActivityData } from "../../api";
import { IBillingActivity } from "../../app/models";

export default function BillingActivity() {
  const navigate = useHistory();
  const [status, setStatus] = useState("succeeded");
  const [billingActivity, setBillingActivity] = useState<
    IBillingActivity[] | null
  >(null);

  const getInitialData = async () => {
    setStatus("loading");

    const response = await getBillingActivityData();

    if (response.ok()) {
      const data = response.data();

      setBillingActivity(data);
      setStatus("succeeded");
    } else {
      setStatus("failed");
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleActivityItemClick = (uuid: string | null) => {
    const slug = uuid === null ? "current-month" : uuid;
    navigate.push(`/billing-activity/${slug}/`);
  };

  const displayLoading = () => {
    return (
      <ViewLoading position="" msg={"Loading billing activity data ..."} />
    );
  };

  const displayFailed = () => {
    return (
      <ViewMessage position="" variant={"success"}>
        <p>Failed to obtain Billing activity data. Please try again later.</p>
      </ViewMessage>
    );
  };

  const displayData = () => {
    if (!billingActivity || billingActivity.length === 0) {
      return <p>Currently there are no billing reports available.</p>;
    }

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Usage</th>
              <th>Cost</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {billingActivity.map(
              ({
                id,
                time,
                usage_ms,
                is_paid,
                paid_amount
              }: IBillingActivity) => {
                return (
                  <tr key={id} onClick={() => handleActivityItemClick(id)}>
                    <td>
                      {id === null
                        ? "Current Month"
                        : moment.utc(time).format("LL")}
                    </td>
                    <td>
                      <Duration miliseconds={usage_ms} />
                    </td>
                    <td>
                      {paid_amount !== null ? (
                        <Price value={paid_amount} />
                      ) : (
                        <>{"Not Available"}</>
                      )}
                    </td>
                    <td>
                      {is_paid ? (
                        <i className={"material-icons s-icon"} title="Paid">
                          check_circle
                        </i>
                      ) : (
                        <i className={"material-icons s-icon"} title="Not paid">
                          cancel
                        </i>
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const content = () => {
    if (status == "loading") {
      return displayLoading();
    } else if (status == "failed") {
      return displayFailed();
    } else {
      return displayData();
    }
  };

  return (
    <div className="component-billingActivity">
      <div className="component-title">
        <h3>Billing Activity</h3>
      </div>
      <Container>
        <Row className="align-items-stretch">
          <Col xl={12}>
            <div className="s-section">
              <div className="s-section-border">
                <div className="s-section-title">
                  <h4 className="h5">Billing Reports</h4>
                </div>
                <br />
                <div className="s-section-body">{content()}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
