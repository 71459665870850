import sonioxLogoTitleWhite from "../../assets/sonioxLogoTitleWhite.svg";

import "./style.scss";

export function AppFooter() {
  return (
    <footer className="app-footer condense">
      <div className="splash">
        <img src={sonioxLogoTitleWhite} className="app-logo" alt="Soniox Cloud" />
      </div>
      <div className="colophon">Copyright © 2022 Soniox</div>
    </footer>
  );
}
