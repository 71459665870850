import { ReactNode } from "react";
import { useResponsiveLayout } from "../../providers/ResponsiveLayoutProvider";

import "./style.scss";

export interface SideNavigationProps {
  children?: ReactNode;
}

export function SideNavigation({ children }: SideNavigationProps) {
  const {
    sideNavigationExpanded,
    setSideNavigationState,
    sideNavigationMinimized,
    setSideNavigationMinimized
  } = useResponsiveLayout();

  const setSideNavigationClassName = () => {
    let className = "app-side-navigation";
    const sideNavigationExpandedClass = sideNavigationExpanded
      ? `${className} s-expanded`
      : `${className} s-collapsed`;
    const sideNavigationMinimizedClass = sideNavigationMinimized
      ? `${className} s-mini-size`
      : `${className} s-regular-size`;
    return `${className} ${sideNavigationExpandedClass} ${sideNavigationMinimizedClass}`;
  };
  //onClick={() => setSideNavigationState(false)}
  return (
    <div className={setSideNavigationClassName()}>
      <div>{children}</div>
    </div>
  );
}
