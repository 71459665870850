import { ReactNode } from "react";

import "./style.scss";

export interface AppBodyProps {
  className?: string;
  children?: ReactNode;
}

export function AppBody(props: AppBodyProps) {
  return <main className={`app-body ${props.className}`}>{props.children}</main>;
}
