import { ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Banner } from "../Banner";
import { AppleAppStoreBadge } from "../AppleAppStoreBadge";

import "./style.scss";

export interface PublicLayoutProps {
  className?: string;
  children?: ReactNode;
  showStoreBadge?: boolean;
}

export function PublicLayout({ className, children, showStoreBadge = true }: PublicLayoutProps) {
  return (
    <div className={`router-component public-view ${className}`}>
      <Container>
        <Row className={"h-100 align-items-center"}>
          <Col className={"align-self-center"}>
            <Banner />
            {showStoreBadge && <AppleAppStoreBadge />}
          </Col>
          <Col className={"align-self-center"}>
            <div className={"s-box"}>
              <div className={"s-box-content"}>{children}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
