import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Duration, Price, ViewLoading, ViewMessage } from "@soniox/common-new";
import { useParams } from "react-router-dom";

import moment from "moment";
import {
  getBillingActivityDetailsData,
  getInvoiceData,
  getUsageReportsData
} from "../../api";
import {
  IBillingActivity,
  IUsageReport,
  IUsageReports
} from "../../app/models";
import ReactPaginate from "react-paginate";

type BillingParams = {
  reportId: string;
};

type InvoiceStatus = "unavailable" | "available" | "loading";

const USAGE_REPORTS_PER_PAGE = 50;

export default function BillingActivityDetails() {
  const { reportId } = useParams<BillingParams>();
  const [status, setStatus] = useState("idle");
  const [usageReportsStatus, setUsageReportsStatus] = useState("idle");
  const [invoiceStatus, setInvoiceStatus] =
    useState<InvoiceStatus>("unavailable");
  const [billingActivityDetailsData, setBillingActivityDetailsData] =
    useState<IBillingActivity | null>(null);
  const [usageReportsData, setUsageReportsData] =
    useState<IUsageReports | null>(null);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const getInitialData = async (page: number) => {
    const id = reportId ? reportId : null;
    const promise1 = getBillingActivityDetailsData(id);
    const promise2 = getUsageReportsData(id, {
      offset: page * USAGE_REPORTS_PER_PAGE,
      limit: USAGE_REPORTS_PER_PAGE
    });

    // Wait for both promises to be resolved.
    const responses = await Promise.all([promise1, promise2]);

    if (responses[0].ok() && responses[1].ok()) {
      const billingActivityDetailsResponseData = responses[0].data();
      const usageReportsResponseData = responses[1].data();

      setBillingActivityDetailsData(billingActivityDetailsResponseData);
      setUsageReportsData(usageReportsResponseData);

      setPageCount(
        Math.ceil(usageReportsResponseData.total / USAGE_REPORTS_PER_PAGE)
      );
      setPage(page);

      if (billingActivityDetailsResponseData.invoice_available) {
        setInvoiceStatus("available");
      }

      setStatus("succeeded");
      setUsageReportsStatus("succeeded");
    } else {
      setStatus("failed");
    }
  };

  useEffect(() => {
    setStatus("loading");
    getInitialData(0);
  }, []);

  const handleDownloadInvoiceClick = async (reportId: string) => {
    if (!reportId) {
      return;
    }

    setInvoiceStatus("loading");

    const response = await getInvoiceData(reportId);

    if (response.ok()) {
      const data = response.data();
      const linkSource = `data:application/pdf;base64,${data.invoice}`;
      const fileName = `soniox-onpremises-invoice-${moment
        .utc(billingActivityDetailsData?.time)
        .format("YYYY-MM-DD")}.pdf`;
      const invoiceLink = document.createElement("a");

      invoiceLink.href = linkSource;
      invoiceLink.download = fileName;
      invoiceLink.click();

      setInvoiceStatus("available");
    } else {
      setInvoiceStatus("unavailable");
    }
  };

  const getUsageReportsPage = async (page: number) => {
    const response = await getUsageReportsData(reportId ? reportId : null, {
      offset: page * USAGE_REPORTS_PER_PAGE,
      limit: USAGE_REPORTS_PER_PAGE
    });

    if (response.ok()) {
      const data = response.data();
      setUsageReportsData(data);
      setPageCount(Math.ceil(data.total / USAGE_REPORTS_PER_PAGE));
      setPage(page);
      setUsageReportsStatus("succeeded");
    } else {
      setUsageReportsStatus("failed");
    }
  };

  const handlePageChange = (page: number) => {
    setUsageReportsStatus("loading");
    getUsageReportsPage(page);
  };

  const renderInvoiceButton = () => {
    const buttonText = () => {
      if (invoiceStatus === "unavailable") {
        return "Invoice not available";
      } else if (invoiceStatus === "loading") {
        return "Loading invoice PDF ...";
      } else {
        return "Download Invoice";
      }
    };

    return (
      <button
        disabled={
          invoiceStatus === "unavailable" || invoiceStatus === "loading"
        }
        className="btn btn-primary btn-invoice"
        onClick={() => handleDownloadInvoiceClick(reportId)}>
        {buttonText()}
      </button>
    );
  };

  const displayUsageReportsLoading = () => {
    return <ViewLoading position="" msg={"Loading usage reports data ..."} />;
  };

  const displayUsageReportsFailed = () => {
    return (
      <ViewMessage position="" variant={"success"}>
        <p>Failed to obtain usage reports. Please try again later.</p>
      </ViewMessage>
    );
  };

  const displayUsageReportsContent = () => {
    if (!usageReportsData) {
      return displayUsageReportsFailed();
    }

    const { total, usage_reports } = usageReportsData;

    return usage_reports.length === 0 ? (
      <p>No usage reports available.</p>
    ) : (
      <>
        <div className="table-responsive" style={{ position: "relative" }}>
          <table className="table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              {usage_reports.map(({ id, time, usage_ms }: IUsageReport) => {
                return (
                  <tr key={id}>
                    <td>{moment.utc(time).format("YYYY-MM-DD HH:mm")}</td>
                    <td>
                      <Duration miliseconds={usage_ms} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {usageReportsStatus == "loading" && displayUsageReportsLoading()}
        </div>

        {total > USAGE_REPORTS_PER_PAGE && (
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="page-link"
            forcePage={page}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => handlePageChange(selected)}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            nextClassName="page-item"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            containerClassName="pagination pagination-container"
            activeClassName="active"
          />
        )}
      </>
    );
  };

  const usageReportsContent = () => {
    return (
      <>
        {(usageReportsStatus == "succeeded" ||
          usageReportsStatus == "loading") &&
          displayUsageReportsContent()}
        {usageReportsStatus == "failed" && displayUsageReportsFailed()}
      </>
    );
  };

  const displayLoading = () => {
    return (
      <ViewLoading position="" msg={"Loading billing activity data ..."} />
    );
  };

  const displayFailed = () => {
    return (
      <ViewMessage position="" variant={"success"}>
        <p>Failed to obtain Billing activity data. Please try again later.</p>
      </ViewMessage>
    );
  };

  const displayContent = () => {
    if (!billingActivityDetailsData) {
      return displayFailed();
    }

    const { id, time, is_paid, usage_ms, paid_amount } =
      billingActivityDetailsData;

    const reportTitle =
      id === null
        ? "Billing Report for the Current Month"
        : `Billing Report on ${moment.utc(time).format("LL")}`;
    console.log("paid_amount", billingActivityDetailsData);
    return (
      <>
        <div className="component-title">
          <h3>{reportTitle}</h3>
        </div>
        <Container>
          <Row className="align-items-stretch">
            <Col xl={12}>
              <div className="s-section">
                <div className="s-section-border">
                  <div className="s-section-body">
                    <Row className="align-items-stretch">
                      <Col>
                        <br />
                        {renderInvoiceButton()}
                        <p>
                          Total Time Transcribed:{" "}
                          <strong>
                            <Duration miliseconds={usage_ms} />
                          </strong>
                        </p>
                        <p>
                          Cost:{" "}
                          {paid_amount !== null ? (
                            <strong>
                              <Price value={paid_amount} />
                            </strong>
                          ) : (
                            <strong>{"Not Available"}</strong>
                          )}
                        </p>
                        <p className="invoice-status">
                          Paid:{" "}
                          {is_paid ? (
                            <i className={"material-icons s-icon"} title="Paid">
                              check_circle
                            </i>
                          ) : (
                            <i
                              className={"material-icons s-icon"}
                              title="Not paid">
                              cancel
                            </i>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <br />
                    <h4 className="h5">Usage Reports</h4>
                    <br />
                    {usageReportsContent()}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const content = () => {
    if (status == "loading" || status == "idle") {
      return displayLoading();
    }
    if (status == "failed") {
      return displayFailed();
    }
    if (status == "succeeded") {
      return displayContent();
    }
  };

  return <div className="component-billingActivityDetails">{content()}</div>;
}
