import { Route, Switch } from "react-router-dom";

import {
  AppHeader,
  AppFooter,
  ResponsiveLayoutProvider,
  useWindowDimensions,
  Route404,
  PrivateRoute
} from "@soniox/common-new";

import { appConfig } from "./app/config";

import PrivateRouteLayout from "./components/PrivateRouteLayout";
import SideNavigationItems from "./components/SideNavigationItems";
import AppHeaderDropdownContent from "./components/AppHeaderDropdownContent";

import { Signin } from "./features/Signin";
import Dashboard from "./features/Dashboard";
import BillingActivity from "./features/BillingActivity";

import { authStore } from "./app/state";

function App() {
  const { isAuthenticated } = authStore();

  const setAppClassName = () => {
    return `app s-screen-size-${useWindowDimensions()?.dimensions?.breakpoint}`;
  };

  return (
    <ResponsiveLayoutProvider>
      <div className={setAppClassName()}>
        <AppHeader
          logoTitle={appConfig.logoTitle}
          isAuthenticated={isAuthenticated}>
          <AppHeaderDropdownContent />
        </AppHeader>
        <Switch>
          <Route path="/signin">
            <Signin />
          </Route>
          <PrivateRoute
            path={["/billing-activity/", "/billing-activity/:reportId"]}
            isAuthenticated={isAuthenticated}
            exact>
            <PrivateRouteLayout sideNavigationItems={<SideNavigationItems />}>
              <BillingActivity />
            </PrivateRouteLayout>
          </PrivateRoute>
          <PrivateRoute
            path={["/dashboard", "/"]}
            isAuthenticated={isAuthenticated}
            exact>
            <PrivateRouteLayout sideNavigationItems={<SideNavigationItems />}>
              <Dashboard />
            </PrivateRouteLayout>
          </PrivateRoute>
          <Route path="*">
            <Route404 />
          </Route>
        </Switch>
        <AppFooter />
      </div>
    </ResponsiveLayoutProvider>
  );
}

export default App;
