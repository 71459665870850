import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";
import { PublicLayout, FieldErrorMessage } from "@soniox/common-new";
import { useAuth } from "../../providers/auth-provider";
import { authStore } from "../../app/state";

type SigninFormData = {
  email: string;
  password: string;
  responseError: string;
};

const signinFormSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Must be 8 characters or more.")
    .required("Required")
});

export const Signin = () => {
  const auth = useAuth();
  const navigate = useHistory();
  const location = useLocation();

  const locationState = (location.state as any) || {
    from: { pathname: "/dashboard" }
  };
  const { setIsAuthenticated } = authStore();

  useEffect(() => {
    if (auth?.ok()) {
      navigate.replace(locationState.from);
    }
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<SigninFormData>({
    defaultValues: {
      email: "",
      password: "",
      responseError: ""
    },
    resolver: yupResolver(signinFormSchema)
  });

  const signinHandler = (response: any) => {
    if (response?.ok()) {
      // Signin succeeded.
      setIsAuthenticated(true);
      navigate.replace(locationState.from);
    } else {
      // Signin failed.
      setError("responseError", {
        type: "server side",
        message: "Invalid email or password."
      });
    }
  };

  const onSubmit = async (data: SigninFormData) => {
    // responseError is not bound to any input, so need to be reset manually
    // https://react-hook-form.com/api/useform/clearerrors
    clearErrors();

    return auth?.signin(data.email, data.password, signinHandler);
  };

  return (
    <PublicLayout className="component-sign-in-view" showStoreBadge={false}>
      <div className="s-box-form">
        <h3>Sign In!</h3>
        <section>
          <form onSubmit={handleSubmit(onSubmit)} className="s-public-form">
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Enter your email."
                {...register("email")}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={<FieldErrorMessage />}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                className="form-control form-control-lg"
                type="password"
                placeholder="Enter your password."
                {...register("password")}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                as={<FieldErrorMessage />}
              />
            </div>

            <div className="form-group">
              <ErrorMessage
                errors={errors}
                name="responseError"
                as={<FieldErrorMessage />}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={isSubmitting}>
              {isSubmitting ? "Signing in ..." : "Sign in"}
            </button>
          </form>
        </section>
      </div>
    </PublicLayout>
  );
};
