import { AppBody } from "../AppBody";

import "./style.scss";

export function Route404() {
  return (
    <AppBody className="component-route-404-view">
      <h1>Error 404</h1>
      <p>Requested page does not exist.</p>
    </AppBody>
  );
}
