import appStoreLogo from "../../assets/app_store_cta.svg";

import "./style.scss";

export function AppleAppStoreBadge() {
  return (
    <div className="a-apple-app-store-badge">
      <a href="https://apps.apple.com/us/app/soniox/id1560199731?itsct=apps_box_badge&amp;itscg=30200">
        <img src={appStoreLogo} alt="Download on the App Store" />
      </a>
    </div>
  );
}
