import sonioxLogoTitleWhite from "../../assets/sonioxLogoTitleWhite.svg";

export function Banner() {
  return (
    <div className={"soniox-banner"}>
      <img src={sonioxLogoTitleWhite} className={"app-logo"} alt={"Soniox Cloud"} />
      <h1 className={"tag-line"}>Spoken Language AI</h1>
    </div>
  );
}
