import { API_PREFIX } from "../app/config";
import { client } from "@soniox/common-new";

export const getBillingActivityData = async (): Promise<any> => {
  return client.authGet(`${API_PREFIX}billing_reports/`, {});
};

export const getBillingActivityDetailsData = async (
  id: string | null
): Promise<any> => {
  const url =
    id === null
      ? `${API_PREFIX}billing_report/`
      : `${API_PREFIX}billing_report/${id}/`;

  return client.authGet(url, {});
};

export const getInvoiceData = async (id: string): Promise<any> => {
  return client.authGet(`${API_PREFIX}billing_report/${id}/invoice/`, {});
};

interface GetBillingActivityDetailsDataParams {
  limit: number;
  offset: number;
}

export const getUsageReportsData = async (
  id: string | null,
  { limit, offset }: GetBillingActivityDetailsDataParams
): Promise<any> => {
  const url =
    id === null
      ? `${API_PREFIX}billing_report/usage_reports/?offset=${offset}&limit=${limit}`
      : `${API_PREFIX}billing_report/${id}/usage_reports/?offset=${offset}&limit=${limit}`;

  return client.authGet(url, {});
};
