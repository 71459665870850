import { ReactNode } from "react";

export interface AppHeaderDropdownProps {
  status: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
}

export function AppHeaderDropdown({ status, title, children, onClose }: AppHeaderDropdownProps) {
  const displayDropdown = () => {
    return (
      <div className="s-popover-wrapper">
        <div className="s-popover-overlay" onClick={() => onClose()}></div>

        <div
          className="popover fade show bs-popover-end"
          role="tooltip"
          data-popper-placement="right">
          <div className="popover-arrow"></div>
          <h3 className="popover-header">{title}</h3>
          <div className="popover-body">{children}</div>
        </div>
      </div>
    );
  };
  if (status) {
    return displayDropdown();
  }
  return null;
}
