const API_PREFIX = "/api/v1/";

interface AppConfigProps {
  baseUrlPath: string;
  redirectUrlSubPath: string;
  // titles
  browserTitle: string;
  logoTitle: string;
}

const appConfig: AppConfigProps = {
  // routing
  baseUrlPath: "",
  redirectUrlSubPath: "/dashboard",
  // titles
  browserTitle: "Soniox On-premises",
  logoTitle: "onpremises"
};

function isDev(): boolean {
  const url = window.location.href;
  return (
    url.startsWith("https://onpremises.dev") ||
    url.startsWith("http://localhost")
  );
}

function isProd(): boolean {
  const url = window.location.href;
  return url.startsWith("https://onpremises.soniox");
}

export { API_PREFIX, appConfig, isDev, isProd };
