import { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";

interface PrivateRouteProps {
  path: string | string[];
  children?: ReactNode;
  isAuthenticated: boolean;
  redirectPath?: string;
  [x: string]: any;
}

export const PrivateRoute = ({
  path,
  children,
  isAuthenticated,
  redirectPath = "/signin",
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};
