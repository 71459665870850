export const JWT_KEY = "jwt";

export const setJwt = (jwt: string) => {
  localStorage.setItem(JWT_KEY, JSON.stringify(jwt));
};

export const unsetJwt = () => {
  localStorage.removeItem(JWT_KEY);
};

export const hasJwt = () => {
  return localStorage.getItem(JWT_KEY) != null;
};

export const getJwt = () => {
  let jwt = localStorage.getItem(JWT_KEY);
  if (jwt == null) {
    return null;
  }
  return JSON.parse(jwt);
};

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
