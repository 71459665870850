import React, { createContext, useContext, useState, ReactNode } from "react";

export interface ResponsiveLayoutProps {
  children: ReactNode | Array<ReactNode>;
}

export interface ResponsiveLayoutInterface {
  sideNavigationExpanded: boolean;
  setSideNavigationState: object;
  sideNavigationMinimized: boolean;
  setSideNavigationMinimized: object;
  toggleSideNavigation: object;
  sideBarExpanded: boolean;
  setSideBarState: object;
  sideBarMinimized: boolean;
  setSideBarMinimized: object;
  toggleSideBar: object;
}

const defaultResponsiveLayoutValues = {
  displayStatusMessage: () => {},
  sideNavigationExpanded: false,
  setSideNavigationState: () => {},
  sideNavigationMinimized: false,
  setSideNavigationMinimized: () => {},
  toggleSideNavigation: {},
  sideBarExpanded: false,
  setSideBarState: () => {},
  sideBarMinimized: true,
  setSideBarMinimized: () => {},
  toggleSideBar: {}
};

const ResponsiveLayoutContext = createContext<ResponsiveLayoutInterface>(
  defaultResponsiveLayoutValues
);

const ResponsiveLayoutProvider = (props: ResponsiveLayoutProps) => {
  const [sideNavigationExpanded, setSideNavigationExpanded] = useState<boolean>(false);
  const [sideNavigationMinimized, setSideNavigationMinimized] = useState<boolean>(false);
  const [sideBarExpanded, setSideBarExpanded] = useState<boolean>(false);
  const [sideBarMinimized, setSideBarMinimized] = useState<boolean>(false);

  const toggleSideNavigation = () => {
    if (!sideNavigationExpanded) {
      setSideNavigationMinimized(false);
    }
    setSideNavigationExpanded(!sideNavigationExpanded);
  };

  const setSideNavigationState = (state: boolean) => {
    setSideNavigationExpanded(state);
  };

  const toggleSideBar = () => {
    console.log("LUKA sideBarExpanded=", sideBarExpanded);
    // if (!sideBarExpanded) {
    //   setSideBarMinimized(false);
    // }
    setSideBarExpanded(!sideBarExpanded);
  };

  const setSideBarState = (state: boolean) => {
    setSideBarExpanded(state);
  };
  return (
    <ResponsiveLayoutContext.Provider
      value={{
        sideNavigationExpanded,
        setSideNavigationState,
        sideNavigationMinimized,
        setSideNavigationMinimized,
        toggleSideNavigation,
        sideBarExpanded,
        setSideBarState,
        sideBarMinimized,
        setSideBarMinimized,
        toggleSideBar
      }}>
      {props.children}
    </ResponsiveLayoutContext.Provider>
  );
};

const useResponsiveLayout = () => {
  return useContext(ResponsiveLayoutContext);
};

export { ResponsiveLayoutProvider, ResponsiveLayoutContext, useResponsiveLayout };
