import { useHistory } from "react-router-dom";
import { authStore } from "../../app/state";
import { useAuth } from "../../providers/auth-provider";

export default function AppHeaderDropdownContent() {
  const auth = useAuth();
  const navigate = useHistory();
  const { setIsAuthenticated } = authStore();

  const handleSignoutButtonClick = () => {
    auth?.signout(() => {
      setIsAuthenticated(false);
      navigate.push("/signin");
    });
  };

  return (
    <div>
      <button className={"btn btn-danger"} onClick={handleSignoutButtonClick}>
        Sign out
      </button>
    </div>
  );
}
